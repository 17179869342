export const formConstants = {
  ACTIVITY_LIST: [
    "Agence de communication",
    "Agence de recrutement / interim",
    "Agence de voyages",
    "Agence web",
    "Agent commercial",
    "Agent d'artistes",
    "Agent immobilier",
    "Agent immobilier + Gestion locative + Syndic",
    "Aide à la personne",
    "Alimentation générale / Épicerie",
    "Ambulance / Taxis conventionnés",
    "Application pour smartphones",
    "Architecture",
    "Architecture d'intérieur",
    "Armurerie",
    "Auto-école",
    "Bar / Débit de boissons",
    "Bijouterie Joaillerie",
    "Boucherie / Charcuterie",
    "Boulangerie / Patisserie",
    "Bricolage et petits travaux",
    "Bureau d'études",
    "Cafétéria / Coffee-shop",
    "Caviste / Vente de vins et spiritueux",
    "Chambres d'hôtes / Tables d'hôtes",
    "Charpentier",
    "Chauffage / Climatisation",
    "Chocolaterie",
    "Commerce de gros de produits industriels",
    "Commerce de produits alimentaires (détail et gros)",
    "Commerce électronique",
    "Comptabilité / Gestion",
    "Conception de sites internet",
    "Conciergerie",
    "Conseil",
    "Conseil en IT",
    "Conseil en IT / web",
    "Conseil en communication",
    "Conseil en communication et médias",
    "Conseil en développement touristique",
    "Conseil en financement public",
    "Conseil en gestion de patrimoine",
    "Conseil en logistique",
    "Conseil en management / stratégie",
    "Conseil en management/stratégie",
    "Conseil en marketing",
    "Conseil en marketing digital",
    "Conseil en ressources humaines",
    "Conseil en transformation digitale",
    "Cours de cuisine",
    "Cours de sport",
    "Courtage en assurance",
    "Courtage en financement immobilier",
    "Courtage prêt immobilier",
    "Courtier bancaire",
    "Courtier en assurance",
    "Couvreur",
    "Diagnostic immobilier",
    "Décontamination (dératisation, etc)",
    "Décoration d'intérieur",
    "Dépannage-Remorquage",
    "Edition de livres",
    "Electricité / Energie",
    "Enseignement artistique",
    "Enseignement sportif",
    "Entreprise générale de bâtiment",
    "Epicerie fine (thé, café, etc)",
    "Escape game",
    "Fast-food / Restauration rapide",
    "Fleuriste",
    "Food truck",
    "Formation continue",
    "Galerie d'art",
    "Garage",
    "Garde d'enfants",
    "Gardiennage",
    "Gestion locative pour le compte de tiers",
    "Glacier",
    "Graphisme (web, 2D, etc)",
    "Grossiste Boissons",
    "Holding",
    "Hébergeur",
    "Hôtel",
    "Import-export",
    "Installation de fibre optique",
    "Institut de beauté / Epilation",
    "Jardinage / Aménagement d'extérieur",
    "Jeux vidéos",
    "Label de musique",
    "Librairie",
    "Lobbying",
    "Location de bateaux",
    "Location de biens immobiliers",
    "Location de biens immobiliers (habitation)",
    "Location de biens immobiliers (professionnel)",
    "Location de biens immobiliers (tout type)",
    "Location de matériel",
    "Location de véhicules automobiles",
    "Location/vente d'instruments de musique",
    "Location/vente de matériel son, lumière et vidéo",
    "Logiciel SaaS",
    "Marchand de biens",
    "Massage",
    "Maçonnerie et gros oeuvre",
    "Maître d'oeuvre",
    "Menuiserie",
    "Montage d'échafaudage",
    "Ménage",
    "Nettoyage industriel",
    "Négoce d'objets d'arts",
    "Organisation d'évènements (spectacles, concerts, évènements culturels, etc)",
    "Organisation d'évènements d'entreprises",
    "Organisation de parcours sportifs et touristiques",
    "Paysagiste",
    "Peinture et vitrerie",
    "Photographie",
    "Plomberie",
    "Plâtrier-plaquiste",
    "Portail internet",
    "Production de contenu digital",
    "Production de produits alimentaires",
    "Production vidéo",
    "Programmation",
    "Restaurant",
    "Revêtement sols et murs",
    "Réceptionniste / Hôte d'accueil",
    "Rénovation",
    "Réparation de matériel électronique",
    "Salle de sport",
    "Secrétariat / Aide administrative",
    "Site e-commerce",
    "Sophrologie / Naturopathie",
    "Soutien scolaire",
    "Spectacle vivant",
    "Studio d'enregistrement",
    "Syndic",
    "Tatoueur",
    "Taxi",
    "Traducteur",
    "Traiteur / Chef à domicile",
    "Transport marchandises",
    "Transport routier de personnes",
    "Travaux d'isolation",
    "VTC",
    "Vente d'articles de chasse et de pêche",
    "Vente d'articles de loisirs et de sport",
    "Vente d'articles de maroquinerie",
    "Vente d'articles de sport",
    "Vente d'articles médicaux et paramédicaux",
    "Vente d'objets d'ameublement, de décoration et d'arts de la table",
    "Vente d'équipements électroménager",
    "Vente de chaussures",
    "Vente de cigarettes électroniques",
    "Vente de décoration végétale et florale d'intérieur",
    "Vente de fruits et légumes",
    "Vente de matériel informatique",
    "Vente de pièces détachées de véhicules",
    "Vente de produits bio",
    "Vente de produits de beauté et bien-être",
    "Vente de textiles (vêtements, tissus, etc.)",
    "Vente de véhicules",
    "Vente de véhicules d'occasion",
    "Vente de véhicules neufs ou d'occasion",
    "Vente de vélos",
    "Vente et location de matériel pour le bâtiment",
    "Vente par correspondance",
    "Voyance",
    "Webdesigner",
    "Wedding planner",
    "vente d'appareils électroniques / télécommunications",
    "Autre",
  ],
};

export const fieldLabels = {
  ref: "Réference du dossier",
  lastName: "Nom",
  firstName: "Prénom",
  email: "Mail",
  phone: "Téléphone",
  societeName: "Dénomination sociale",
  siegeSocial: "Siège social",
  dateCreation: "Date de création souhaitée",
  firstTimeCreation: "S'agit-il de votre première création d’entreprise ?",
  previousCreation: "Précédente création",
  userStatus: "Statut de l'utilisateur",
  nomSociete: "Nom de votre société",
  copyright: "Droits d'auteur",
  objetSocialeDesc: "Description précise de l'objet social",
  activity: "Activité principale",
  otherActivity: "Autre activité",
  activityChambreDesMetiers:
    "Votre activité relève-t-elle de la chambre des métiers ?",
  activityChambreDesMetiersGere:
    "Votre activité est-elle gérée par la chambre des métiers ? Pour les activités artisanales, l'enregistrement auprès de la chambre des métiers et de l'artisanat est obligatoire. Cet enregistrement est géré et coûte 79€ HT.",
  montantCapitalSocial: "Montant du capital social",
  apport: "Apports en numéraire (montant)",
  typeAssocieUnique: "Type d’associé unique",
  associePhysiqueNom: "Nom de l'associé unique",
  associePhysiquePrenom: "Prénom de l'associé unique",
  associePhysiqueDateNaissance: "Date de naissance de l'associé unique",
  associePhysiqueLieuNaissance: "Lieu de naissance de l'associé unique",
  associePhysiqueSituationMatrimonial:
    "Situation matrimonial de l'associé unique",
  associePhysiqueNationalite: "Nationalité de l'associé unique",
  associePhysiqueAdresse: "Adresse de l'associé unique",
  associeMoraleDenominationSociale: "Dénomination sociale de l'associé unique",
  associeMoraleFormeJuridique: "Forme juridique de l'associé unique",
  associeMoraleCapitalSocial: "Capital social de l'associé unique",
  associeMoraleAdresseNumeroRue: "Numéro et rue de l'associé unique",
  associeMoraleAdresseCodePostal: "Code postal de l'associé unique",
  associeMoraleAdresseVille: "Ville de l'associé unique",
  associeMoraleNumeroImmatriculation:
    "Numéro d'immatriculation de l'associé unique",
  associeMoraleVilleImmatriculation:
    "Ville où est immatriculé l'associé unique",
  associeMoraleNomRepresentant: "Nom du représentant légal",
  associeMoraleQualiteRepresentant: "Qualité du représentant légal",
  identitePresident: "Identité du président",
  presidentPhysiqueNom: "Nom",
  presidentPhysiquePrenom: "Prénom",
  presidentPhysiqueDateNaissance: "Date de naissance",
  presidentPhysiqueLieuNaissance: "Lieu de naissance",
  presidentPhysiqueSituationMatrimonial: "Situation matrimonial",
  presidentPhysiqueNationalite: "Nationalité",
  presidentPhysiqueAdresse: "Adresse",
  presidentMoraleDenominationSociale: "Dénomination sociale",
  presidentMoraleFormeJuridique: "Forme juridique",
  presidentMoraleCapitalSocial: "Capital social",
  presidentMoraleAdresse: "Adresse",
  presidentMoraleNumeroImmatriculation: "Numéro d'immatriculation",
  presidentMoraleVilleImmatriculation: "Ville où est immatriculé le president",
  presidentMoraleNomRepresentant: "Nom du représentant légal",
  presidentMoraleQualiteRepresentant: "Qualité du représentant légal",
  nommerDirecteurGeneral: "Souhaitez-vous nommer un directeur général",
  identiteDirecteur: "Identité du directeur",
  directeurPhysiqueNom: "Nom",
  directeurPhysiquePrenom: "Prénom",
  directeurPhysiqueDateNaissance: "Date de naissance",
  directeurPhysiqueLieuNaissance: "Lieu de naissance",
  directeurPhysiqueSituationMatrimonial: "Situation matrimonial",
  directeurPhysiqueNationalite: "Nationalité",
  directeurPhysiqueAdresse: "Adresse",
  directeurMoraleDenominationSociale: "Dénomination sociale",
  directeurMoraleFormeJuridique: "Forme juridique",
  directeurMoraleCapitalSocial: "Capital social",
  directeurMoraleAdresse: "Adresse",
  directeurMoraleNumeroImmatriculation: "Numéro d'immatriculation",
  directeurMoraleVilleImmatriculation: "Ville où est immatriculé le president",
  directeurMoraleNomRepresentant: "Nom du représentant légal",
  directeurMoraleQualiteRepresentant: "Qualité du représentant légal",
};

export const formulaireSasu1 = [
  {
    label: "Identification",
    form: {
      title:
        "Qui effectue la démarche de création d’entreprise avec Alcée Avocats ?",
      questions: [
        { key: "lastName", type: "textfield" },
        { key: "firstName", type: "textfield" },
        { key: "email", type: "textfield" },
        { key: "phone", type: "textfield" },
      ],
    },
  },
  {
    label: "Société",
    form: {
      title: "Informations générales sur la société",
      questions: [
        { key: "societeName", type: "textfield" },
        { key: "siegeSocial", type: "address" },
        { key: "dateCreation", type: "calendar" },
      ],
    },
  },
  {
    label: "Création",
    form: {
      title: "Création",
      questions: [
        {
          key: "firstTimeCreation",
          type: "radio",
          options: ["Oui", "Non"],
          displayLabel: true,
        },
        {
          key: "previousCreation",
          type: "radio",
          options: [
            "J’ai déjà créé une auto entreprise",
            "J'ai déjà créé une société commerciale",
          ],
          condition: { key: "firstTimeCreation", value: "Non" },
        },
        {
          key: "userStatus",
          type: "radio",
          options: [
            "Salarié",
            "Demandeur d’emploi",
            "Entrepreneur",
            "Étudiant",
          ],
          displayLabel: true,
        },
      ],
    },
  },
  {
    label: "Protection",
    form: {
      title:
        "Souhaitez-vous protéger le nom de votre société en déposant une marque ?",
      questions: [
        {
          key: "copyright",
          type: "radio",
          options: ["Oui", "Non"],
        },
      ],
    },
  },
  {
    label: "Activité",
    form: {
      title: "Activités et objet social",
      questions: [
        { key: "objetSocialeDesc", type: "textfield" },
        { key: "activity", type: "activityList" },
        {
          key: "otherActivity",
          type: "textfield",
          condition: { key: "activity", value: "Autre" },
        },
        {
          displayLabel: true,
          key: "activityChambreDesMetiers",
          type: "radio",
          options: ["Oui", "Non"],
        },
        {
          displayLabel: true,
          key: "activityChambreDesMetiersGere",
          type: "radio",
          options: ["Oui", "Non", "Plus tard"],
          condition: { key: "activityChambreDesMetiers", value: "Oui" },
        },
      ],
    },
  },
  {
    label: "Capital social",
    form: {
      title: "Capital social",
      questions: [
        { key: "montantCapitalSocial", type: "numberfield" },
        { key: "apport", type: "numberfield" },
      ],
    },
  },
  {
    label: "Associé",
    form: {
      title: "Identité de l’associé unique",
      questions: [
        {
          key: "typeAssocieUnique",
          type: "radio",
          options: ["Personne physique", "Personne morale"],
          displayLabel: true,
        },
        {
          key: "associePhysiqueNom",
          type: "textfield",
          condition: { key: "typeAssocieUnique", value: "Personne physique" },
        },
        {
          key: "associePhysiquePrenom",
          type: "textfield",
          condition: { key: "typeAssocieUnique", value: "Personne physique" },
        },
        {
          key: "associePhysiqueDateNaissance",
          type: "calendar",
          condition: { key: "typeAssocieUnique", value: "Personne physique" },
        },
        {
          key: "associePhysiqueLieuNaissance",
          type: "textfield",
          condition: { key: "typeAssocieUnique", value: "Personne physique" },
        },
        {
          key: "associePhysiqueSituationMatrimonial",
          type: "radio",
          options: [
            "Célibataire non lié par un pacte civil de solidarité",
            "Célibataire, Lié par un pacte civil de solidarité",
            "Marié sous le régime de la communauté de biens réduite aux acquêts, à défaut de contrat de mariage",
            "Marié sous le régime de la communauté universelle",
            "Marié sous le régime de la séparation de biens",
          ],
          displayLabel: true,
          condition: { key: "typeAssocieUnique", value: "Personne physique" },
        },
        {
          key: "associePhysiqueNationalite",
          type: "textfield",
          condition: { key: "typeAssocieUnique", value: "Personne physique" },
        },
        {
          key: "associePhysiqueAdresse",
          type: "address",
          condition: { key: "typeAssocieUnique", value: "Personne physique" },
        },
        {
          key: "associeMoraleDenominationSociale",
          type: "textfield",
          condition: { key: "typeAssocieUnique", value: "Personne morale" },
        },
        {
          key: "associeMoraleFormeJuridique",
          type: "textfield",
          condition: { key: "typeAssocieUnique", value: "Personne morale" },
        },
        {
          key: "associeMoraleCapitalSocial",
          type: "numberfield",
          condition: { key: "typeAssocieUnique", value: "Personne morale" },
        },
        {
          key: "associeMoraleAdresse",
          type: "address",
          condition: { key: "typeAssocieUnique", value: "Personne morale" },
        },
        {
          key: "associeMoraleNumeroImmatriculation",
          type: "textfield",
          condition: { key: "typeAssocieUnique", value: "Personne morale" },
        },
        {
          key: "associeMoraleVilleImmatriculation",
          type: "textfield",
          condition: { key: "typeAssocieUnique", value: "Personne morale" },
        },
        {
          key: "associeMoraleNomRepresentant",
          type: "textfield",
          condition: { key: "typeAssocieUnique", value: "Personne morale" },
        },
        {
          key: "associeMoraleQualiteRepresentant",
          type: "textfield",
          condition: { key: "typeAssocieUnique", value: "Personne morale" },
        },
      ],
    },
  },
  {
    label: "Direction",
    form: {
      title: "Président",
      questions: [
        {
          key: "identitePresident",
          type: "radio",
          options: [
            "Personne physique",
            "Personne morale",
            "Même personne que l'associé",
          ],
        },
        {
          key: "presidentPhysiqueNom",
          type: "textfield",
          condition: { key: "identitePresident", value: "Personne physique" },
        },
        {
          key: "presidentPhysiquePrenom",
          type: "textfield",
          condition: { key: "identitePresident", value: "Personne physique" },
        },
        {
          key: "presidentPhysiqueDateNaissance",
          type: "calendar",
          condition: { key: "identitePresident", value: "Personne physique" },
        },
        {
          key: "presidentPhysiqueLieuNaissance",
          type: "textfield",
          condition: { key: "identitePresident", value: "Personne physique" },
        },
        {
          key: "presidentPhysiqueSituationMatrimonial",
          type: "radio",
          options: [
            "Célibataire non lié par un pacte civil de solidarité",
            "Célibataire, Lié par un pacte civil de solidarité",
            "Marié sous le régime de la communauté de biens réduite aux acquêts, à défaut de contrat de mariage",
            "Marié sous le régime de la communauté universelle",
            "Marié sous le régime de la séparation de biens",
          ],
          displayLabel: true,
          condition: { key: "identitePresident", value: "Personne physique" },
        },
        {
          key: "presidentPhysiqueNationalite",
          type: "textfield",
          condition: { key: "identitePresident", value: "Personne physique" },
        },
        {
          key: "presidentPhysiqueAdresse",
          type: "address",
          condition: { key: "identitePresident", value: "Personne physique" },
        },
        {
          key: "presidentMoraleDenominationSociale",
          type: "textfield",
          condition: { key: "identitePresident", value: "Personne morale" },
        },
        {
          key: "presidentMoraleFormeJuridique",
          type: "textfield",
          condition: { key: "identitePresident", value: "Personne morale" },
        },
        {
          key: "presidentMoraleCapitalSocial",
          type: "numberfield",
          condition: { key: "identitePresident", value: "Personne morale" },
        },
        {
          key: "presidentMoraleAdresse",
          type: "address",
          condition: { key: "identitePresident", value: "Personne morale" },
        },
        {
          key: "presidentMoraleNumeroImmatriculation",
          type: "textfield",
          condition: { key: "identitePresident", value: "Personne morale" },
        },
        {
          key: "presidentMoraleVilleImmatriculation",
          type: "textfield",
          condition: { key: "identitePresident", value: "Personne morale" },
        },
        {
          key: "presidentMoraleNomRepresentant",
          type: "textfield",
          condition: { key: "identitePresident", value: "Personne morale" },
        },
        {
          key: "presidentMoraleQualiteRepresentant",
          type: "textfield",
          condition: { key: "identitePresident", value: "Personne morale" },
        },
        {
          key: "nommerDirecteurGeneral",
          type: "radio",
          options: ["Oui", "Non"],
          displayLabel: true,
        },
        {
          key: "identiteDirecteur",
          type: "radio",
          options: ["Personne physique", "Personne morale"],
          condition: { key: "nommerDirecteurGeneral", value: "Oui" },
          displayLabel: true,
        },
        {
          key: "directeurPhysiqueNom",
          type: "textfield",
          condition: { key: "identiteDirecteur", value: "Personne physique" },
        },
        {
          key: "directeurPhysiquePrenom",
          type: "textfield",
          condition: { key: "identiteDirecteur", value: "Personne physique" },
        },
        {
          key: "directeurPhysiqueDateNaissance",
          type: "calendar",
          condition: { key: "identiteDirecteur", value: "Personne physique" },
        },
        {
          key: "directeurPhysiqueLieuNaissance",
          type: "textfield",
          condition: { key: "identiteDirecteur", value: "Personne physique" },
        },
        {
          key: "directeurPhysiqueSituationMatrimonial",
          type: "radio",
          options: ["Célibataire", "Marié", "Concubinage", "Divorcé"],
          displayLabel: true,
          condition: { key: "identiteDirecteur", value: "Personne physique" },
        },
        {
          key: "directeurPhysiqueNationalite",
          type: "textfield",
          condition: { key: "identiteDirecteur", value: "Personne physique" },
        },
        {
          key: "directeurPhysiqueAdresse",
          type: "address",
          condition: { key: "identiteDirecteur", value: "Personne physique" },
        },
        {
          key: "directeurMoraleDenominationSociale",
          type: "textfield",
          condition: { key: "identiteDirecteur", value: "Personne morale" },
        },
        {
          key: "directeurMoraleFormeJuridique",
          type: "textfield",
          condition: { key: "identiteDirecteur", value: "Personne morale" },
        },
        {
          key: "directeurMoraleCapitalSocial",
          type: "numberfield",
          condition: { key: "identiteDirecteur", value: "Personne morale" },
        },
        {
          key: "directeurMoraleAdresse",
          type: "address",
          condition: { key: "identiteDirecteur", value: "Personne morale" },
        },
        {
          key: "directeurMoraleNumeroImmatriculation",
          type: "textfield",
          condition: { key: "identiteDirecteur", value: "Personne morale" },
        },
        {
          key: "directeurMoraleVilleImmatriculation",
          type: "textfield",
          condition: { key: "identiteDirecteur", value: "Personne morale" },
        },
        {
          key: "directeurMoraleNomRepresentant",
          type: "textfield",
          condition: { key: "identiteDirecteur", value: "Personne morale" },
        },
        {
          key: "directeurMoraleQualiteRepresentant",
          type: "textfield",
          condition: { key: "identiteDirecteur", value: "Personne morale" },
        },
      ],
    },
  },
  {
    label: "Récapitulatif",
    form: {
      title: "Récapitulatif des renseignements fournis",
      type: "recapitulatif",
    },
  }, // Page 10
  {
    label: "Formule",
    form: {
      title: "Choisissez la formule qui vous correspond",
      type: "stripe",
    },
  }, // Page 11
];

export const iso4217 = {
  AED: {
    name: "United Arab Emirates Dirham",
    demonym: "UAE",
    majorSingle: "Dirham",
    majorPlural: "Dirhams",
    ISOnum: 784,
    symbol: "د.إ.",
    symbolNative: "د.إ.",
    minorSingle: "Fils",
    minorPlural: "Fils",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  AFN: {
    name: "Afghan Afghani",
    demonym: "Afghan",
    majorSingle: "Afghani",
    majorPlural: "Afghani",
    ISOnum: 971,
    symbol: "Af",
    symbolNative: "؋",
    minorSingle: "Pul",
    minorPlural: "Pul",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  ALL: {
    name: "Albanian Lek",
    demonym: "Albanian",
    majorSingle: "Lek",
    majorPlural: "Lekë",
    ISOnum: 8,
    symbol: "L",
    symbolNative: "L",
    minorSingle: "Qindarka",
    minorPlural: "Qindarka",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  AMD: {
    name: "Armenian Dram",
    demonym: "Armenian",
    majorSingle: "Dram",
    majorPlural: "Dram",
    ISOnum: 51,
    symbol: "֏",
    symbolNative: "դր",
    minorSingle: "Luma",
    minorPlural: "Luma",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  ANG: {
    name: "Netherlands Antillean Guilder",
    demonym: "Netherlands Antillean",
    majorSingle: "Guilder",
    majorPlural: "Guilders",
    ISOnum: 532,
    symbol: "ƒ",
    symbolNative: "ƒ",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  AOA: {
    name: "Angolan Kwanza",
    demonym: "Angolan",
    majorSingle: "Kwanza",
    majorPlural: "Kwanza",
    ISOnum: 973,
    symbol: "Kz",
    symbolNative: "Kz",
    minorSingle: "Centimo",
    minorPlural: "Centimos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  ARS: {
    name: "Argentine Peso",
    demonym: "Argentine",
    majorSingle: "Peso",
    majorPlural: "Pesos",
    ISOnum: 32,
    symbol: "AR$",
    symbolNative: "$",
    minorSingle: "Centavo",
    minorPlural: "Centavos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  AUD: {
    name: "Australian Dollar",
    demonym: "Australian",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 36,
    symbol: "AU$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  AWG: {
    name: "Aruban Florin",
    demonym: "Aruban",
    majorSingle: "Florin",
    majorPlural: "Florin",
    ISOnum: 533,
    symbol: "ƒ",
    symbolNative: "ƒ",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  AZN: {
    name: "Azerbaijani Manat",
    demonym: "Azerbaijani",
    majorSingle: "Manat",
    majorPlural: "Manat",
    ISOnum: 944,
    symbol: "ман",
    symbolNative: "₼",
    minorSingle: "Qapik",
    minorPlural: "Qapik",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  BAM: {
    name: "Bosnia and Herzegovina Convertible Mark",
    demonym: "Bosnia-Herzegovina",
    majorSingle: "Convertible Mark",
    majorPlural: "Marks",
    ISOnum: 977,
    symbol: "KM",
    symbolNative: "КМ",
    minorSingle: "Fening",
    minorPlural: "Fening",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  BBD: {
    name: "Barbadian Dollar",
    demonym: "Barbadian",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 52,
    symbol: "BBD$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  BDT: {
    name: "Bangladeshi Taka",
    demonym: "Bangladeshi",
    majorSingle: "Taka",
    majorPlural: "Taka",
    ISOnum: 50,
    symbol: "৳",
    symbolNative: "৳",
    minorSingle: "Poisha",
    minorPlural: "Poisha",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  BGN: {
    name: "Bulgarian Lev",
    demonym: "Bulgarian",
    majorSingle: "Lev",
    majorPlural: "Leva",
    ISOnum: 975,
    symbol: "лв.",
    symbolNative: "лв.",
    minorSingle: "Stotinka",
    minorPlural: "Stotinki",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  BHD: {
    name: "Bahraini Dinar",
    demonym: "Bahraini",
    majorSingle: "Dinar",
    majorPlural: "Dinars",
    ISOnum: 48,
    symbol: "BD",
    symbolNative: "د.ب.",
    minorSingle: "Fils",
    minorPlural: "Fils",
    ISOdigits: 3,
    decimals: 3,
    numToBasic: 1000,
  },
  BIF: {
    name: "Burundian Franc",
    demonym: "Burundian",
    majorSingle: "Franc",
    majorPlural: "Francs",
    ISOnum: 108,
    symbol: "FBu",
    symbolNative: "FBu",
    minorSingle: "Centime",
    minorPlural: "Centimes",
    ISOdigits: 0,
    decimals: 2,
    numToBasic: 100,
  },
  BMD: {
    name: "Bermudian Dollar",
    demonym: "Bermudian",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 60,
    symbol: "$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  BND: {
    name: "Brunei Dollar",
    demonym: "Brunei",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 96,
    symbol: "B$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  BOB: {
    name: "Bolivian Boliviano",
    demonym: "Bolivian",
    majorSingle: "Boliviano",
    majorPlural: "Bolivianos",
    ISOnum: 68,
    symbol: "Bs.",
    symbolNative: "Bs.",
    minorSingle: "Centavo",
    minorPlural: "Centavos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  BRL: {
    name: "Brazilian Real",
    demonym: "Brazilian",
    majorSingle: "Real",
    majorPlural: "Reais",
    ISOnum: 986,
    symbol: "R$",
    symbolNative: "R$",
    minorSingle: "Centavo",
    minorPlural: "Centavos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  BSD: {
    name: "Bahamian Dollar",
    demonym: "Bahamian",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 44,
    symbol: "$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  BTN: {
    name: "Bhutanese Ngultrum",
    demonym: "Bhutanese",
    majorSingle: "Ngultrum",
    majorPlural: "Ngultrums",
    ISOnum: 64,
    symbol: "Nu.",
    symbolNative: "Nu.",
    minorSingle: "Chetrum",
    minorPlural: "Chetrums",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  BWP: {
    name: "Botswana Pula",
    demonym: "Botswana",
    majorSingle: "Pula",
    majorPlural: "Pula",
    ISOnum: 72,
    symbol: "P",
    symbolNative: "P",
    minorSingle: "Thebe",
    minorPlural: "Thebe",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  BYN: {
    name: "Belarusian Ruble",
    demonym: "Belarusian",
    majorSingle: "Ruble",
    majorPlural: "Rubles",
    ISOnum: 933,
    symbol: "Br",
    symbolNative: "руб.",
    minorSingle: "Kapiejka",
    minorPlural: "Kapiejka",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  BZD: {
    name: "Belize Dollar",
    demonym: "Belize",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 84,
    symbol: "BZ$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  CAD: {
    name: "Canadian Dollar",
    demonym: "Canadian",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 124,
    symbol: "CA$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  CDF: {
    name: "Congolese Franc",
    demonym: "Congolese",
    majorSingle: "Franc",
    majorPlural: "Francs",
    ISOnum: 976,
    symbol: "FC",
    symbolNative: "₣",
    minorSingle: "Centime",
    minorPlural: "Centimes",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  CHF: {
    name: "Swiss Franc",
    demonym: "Swiss",
    majorSingle: "Franc",
    majorPlural: "Francs",
    ISOnum: 756,
    symbol: "Fr.",
    symbolNative: "₣",
    minorSingle: "Centime",
    minorPlural: "Centimes",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  CKD: {
    name: "Cook Islands Dollar",
    demonym: "Cook Islands",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: null,
    symbol: "$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  CLP: {
    name: "Chilean Peso",
    demonym: "Chilean",
    majorSingle: "Peso",
    majorPlural: "Pesos",
    ISOnum: 152,
    symbol: "CL$",
    symbolNative: "$",
    minorSingle: "Centavo",
    minorPlural: "Centavos",
    ISOdigits: 0,
    decimals: 0,
    numToBasic: 100,
  },
  CNY: {
    name: "Chinese Yuan",
    demonym: "Chinese",
    majorSingle: "Yuan",
    majorPlural: "Yuan",
    ISOnum: 156,
    symbol: "CN¥",
    symbolNative: "¥元",
    minorSingle: "Fen",
    minorPlural: "Fen",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  COP: {
    name: "Colombian Peso",
    demonym: "Colombian",
    majorSingle: "Peso",
    majorPlural: "Pesos",
    ISOnum: 170,
    symbol: "CO$",
    symbolNative: "$",
    minorSingle: "Centavo",
    minorPlural: "Centavos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  CRC: {
    name: "Costa Rican Colon",
    demonym: "Costa Rican",
    majorSingle: "Colón",
    majorPlural: "Colones",
    ISOnum: 188,
    symbol: "₡",
    symbolNative: "₡",
    minorSingle: "Centimo",
    minorPlural: "Centimos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  CUC: {
    name: "Cuban convertible Peso",
    demonym: "Cuban Convertible",
    majorSingle: "Peso",
    majorPlural: "Pesos",
    ISOnum: 931,
    symbol: "CUC$",
    symbolNative: "$",
    minorSingle: "Centavo",
    minorPlural: "Centavos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  CUP: {
    name: "Cuban Peso",
    demonym: "Cuban",
    majorSingle: "Peso",
    majorPlural: "Pesos",
    ISOnum: 192,
    symbol: "$MN",
    symbolNative: "₱",
    minorSingle: "Centavo",
    minorPlural: "Centavos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  CVE: {
    name: "Cabo Verdean Escudo",
    demonym: "Cabo Verdean",
    majorSingle: "Escudo",
    majorPlural: "Escudo",
    ISOnum: 132,
    symbol: "CV$",
    symbolNative: "$",
    minorSingle: "Centavo",
    minorPlural: "Centavos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  CZK: {
    name: "Czech Koruna",
    demonym: "Czech",
    majorSingle: "Koruna",
    majorPlural: "Koruny",
    ISOnum: 203,
    symbol: "Kč",
    symbolNative: "Kč",
    minorSingle: "Haléř",
    minorPlural: "Haléř",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  DJF: {
    name: "Djiboutian Franc",
    demonym: "Djiboutian",
    majorSingle: "Franc",
    majorPlural: "Francs",
    ISOnum: 262,
    symbol: "Fdj",
    symbolNative: "ف.ج.",
    minorSingle: "Centime",
    minorPlural: "Centimes",
    ISOdigits: 0,
    decimals: 2,
    numToBasic: 100,
  },
  DKK: {
    name: "Danish Krone",
    demonym: "Danish",
    majorSingle: "Krone",
    majorPlural: "Kroner",
    ISOnum: 208,
    symbol: "kr.",
    symbolNative: "kr.",
    minorSingle: "Øre",
    minorPlural: "Øre",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  DOP: {
    name: "Dominican Peso",
    demonym: "Dominican",
    majorSingle: "Peso",
    majorPlural: "Pesos",
    ISOnum: 214,
    symbol: "RD$",
    symbolNative: "$",
    minorSingle: "Centavo",
    minorPlural: "Centavos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  DZD: {
    name: "Algerian Dinar",
    demonym: "Algerian",
    majorSingle: "Dinar",
    majorPlural: "Dinars",
    ISOnum: 12,
    symbol: "DA",
    symbolNative: "د.ج.",
    minorSingle: "Santeem",
    minorPlural: "Santeems",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  EGP: {
    name: "Egyptian Pound",
    demonym: "Egyptian",
    majorSingle: "Pound",
    majorPlural: "Pounds",
    ISOnum: 818,
    symbol: "E£",
    symbolNative: "ج.م.",
    minorSingle: "Qirsh",
    minorPlural: "Qirsh",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  EHP: {
    name: "Sahrawi Peseta",
    demonym: "Sahrawi",
    majorSingle: "Peseta",
    majorPlural: "Pesetas",
    ISOnum: null,
    symbol: "Ptas.",
    symbolNative: "Ptas.",
    minorSingle: "Céntimo",
    minorPlural: "Céntimos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  ERN: {
    name: "Eritrean Nakfa",
    demonym: "Eritrean",
    majorSingle: "Nakfa",
    majorPlural: "Nakfa",
    ISOnum: 232,
    symbol: "Nkf",
    symbolNative: "ناكفا",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  ETB: {
    name: "Ethiopian Birr",
    demonym: "Ethiopian",
    majorSingle: "Birr",
    majorPlural: "Birr",
    ISOnum: 230,
    symbol: "Br",
    symbolNative: "ብር",
    minorSingle: "Santim",
    minorPlural: "Santim",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  EUR: {
    name: "Euro",
    demonym: "",
    majorSingle: "Euro",
    majorPlural: "Euros",
    ISOnum: 978,
    symbol: "€",
    symbolNative: "€",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  FJD: {
    name: "Fijian Dollar",
    demonym: "Fijian",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 242,
    symbol: "FJ$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  FKP: {
    name: "Falkland Islands Pound",
    demonym: "Falkland Islands",
    majorSingle: "Pound",
    majorPlural: "Pounds",
    ISOnum: 238,
    symbol: "FK£",
    symbolNative: "£",
    minorSingle: "Penny",
    minorPlural: "Pence",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  FOK: {
    name: "Faroese Króna",
    demonym: "Faroese",
    majorSingle: "Króna",
    majorPlural: "Krónas",
    ISOnum: null,
    symbol: "kr",
    symbolNative: "kr",
    minorSingle: "Oyra",
    minorPlural: "Oyra",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  GBP: {
    name: "Pound Sterling",
    demonym: "Pound Sterling",
    majorSingle: "Pound",
    majorPlural: "Pounds",
    ISOnum: 826,
    symbol: "£",
    symbolNative: "£",
    minorSingle: "Penny",
    minorPlural: "Pence",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  GEL: {
    name: "Georgian Lari",
    demonym: "Georgian",
    majorSingle: "Lari",
    majorPlural: "Lari",
    ISOnum: 981,
    symbol: "₾",
    symbolNative: "₾",
    minorSingle: "Tetri",
    minorPlural: "Tetri",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  GGP: {
    name: "Guernsey Pound",
    demonym: "Guernsey",
    majorSingle: "Pound",
    majorPlural: "Pounds",
    ISOnum: null,
    symbol: "£",
    symbolNative: "£",
    minorSingle: "Penny",
    minorPlural: "Pence",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  GHS: {
    name: "Ghanaian Cedi",
    demonym: "Ghanaian",
    majorSingle: "Cedi",
    majorPlural: "Cedis",
    ISOnum: 936,
    symbol: "GH₵",
    symbolNative: "₵",
    minorSingle: "Pesewa",
    minorPlural: "Pesewas",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  GIP: {
    name: "Gibraltar Pound",
    demonym: "Gibraltar",
    majorSingle: "Pound",
    majorPlural: "Pounds",
    ISOnum: 292,
    symbol: "£",
    symbolNative: "£",
    minorSingle: "Penny",
    minorPlural: "Pence",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  GMD: {
    name: "Gambian Dalasi",
    demonym: "Gambian",
    majorSingle: "Dalasi",
    majorPlural: "Dalasis",
    ISOnum: 270,
    symbol: "D",
    symbolNative: "D",
    minorSingle: "Butut",
    minorPlural: "Bututs",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  GNF: {
    name: "Guinean Franc",
    demonym: "Guinean",
    majorSingle: "Franc",
    majorPlural: "Francs",
    ISOnum: 324,
    symbol: "FG",
    symbolNative: "FG",
    minorSingle: "Centime",
    minorPlural: "Centimes",
    ISOdigits: 0,
    decimals: 2,
    numToBasic: 100,
  },
  GTQ: {
    name: "Guatemalan Quetzal",
    demonym: "Guatemalan",
    majorSingle: "Quetzal",
    majorPlural: "Quetzales",
    ISOnum: 320,
    symbol: "Q",
    symbolNative: "$",
    minorSingle: "Centavo",
    minorPlural: "Centavos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  GYD: {
    name: "Guyanese Dollar",
    demonym: "Guyanese",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 328,
    symbol: "G$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  HKD: {
    name: "Hong Kong Dollar",
    demonym: "Hong Kong",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 344,
    symbol: "HK$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  HNL: {
    name: "Honduran Lempira",
    demonym: "Honduran",
    majorSingle: "Lempira",
    majorPlural: "Lempiras",
    ISOnum: 340,
    symbol: "L",
    symbolNative: "L",
    minorSingle: "Centavo",
    minorPlural: "Centavos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  HRK: {
    name: "Croatian Kuna",
    demonym: "Croatian",
    majorSingle: "Kuna",
    majorPlural: "Kuna",
    ISOnum: 191,
    symbol: "kn",
    symbolNative: "kn",
    minorSingle: "Lipa",
    minorPlural: "Lipa",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  HTG: {
    name: "Haitian Gourde",
    demonym: "Haitian",
    majorSingle: "Gourde",
    majorPlural: "Gourdes",
    ISOnum: 332,
    symbol: "G",
    symbolNative: "G",
    minorSingle: "Centime",
    minorPlural: "Centimes",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  HUF: {
    name: "Hungarian Forint",
    demonym: "Hungarian",
    majorSingle: "Forint",
    majorPlural: "Forint",
    ISOnum: 348,
    symbol: "Ft",
    symbolNative: "Ft",
    minorSingle: "fillér",
    minorPlural: "fillér",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  IDR: {
    name: "Indonesian Rupiah",
    demonym: "Indonesian",
    majorSingle: "Rupiah",
    majorPlural: "Rupiah",
    ISOnum: 360,
    symbol: "Rp",
    symbolNative: "Rp",
    minorSingle: "Sen",
    minorPlural: "Sen",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  ILS: {
    name: "Israeli new Shekel",
    demonym: "Israeli",
    majorSingle: "Shekel",
    majorPlural: "Shekels",
    ISOnum: 376,
    symbol: "₪",
    symbolNative: "₪",
    minorSingle: "Agora",
    minorPlural: "Agoras",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  IMP: {
    name: "Manx Pound",
    demonym: "Manx",
    majorSingle: "Pound",
    majorPlural: "Pounds",
    ISOnum: null,
    symbol: "£",
    symbolNative: "£",
    minorSingle: "Penny",
    minorPlural: "Pence",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  INR: {
    name: "Indian Rupee",
    demonym: "Indian",
    majorSingle: "Rupee",
    majorPlural: "Rupees",
    ISOnum: 356,
    symbol: "Rs.",
    symbolNative: "₹",
    minorSingle: "Paisa",
    minorPlural: "Paise",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  IQD: {
    name: "Iraqi Dinar",
    demonym: "Iraqi",
    majorSingle: "Dinar",
    majorPlural: "Dinars",
    ISOnum: 368,
    symbol: "د.ع.",
    symbolNative: "د.ع.",
    minorSingle: "Fils",
    minorPlural: "Fils",
    ISOdigits: 3,
    decimals: 3,
    numToBasic: 1000,
  },
  IRR: {
    name: "Iranian Rial",
    demonym: "Iranian",
    majorSingle: "Rial",
    majorPlural: "Rials",
    ISOnum: 364,
    symbol: "﷼",
    symbolNative: "﷼",
    minorSingle: "Dinar",
    minorPlural: "Dinars",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  ISK: {
    name: "Icelandic Krona",
    demonym: "Icelandic",
    majorSingle: "Krona",
    majorPlural: "Krónur",
    ISOnum: 352,
    symbol: "kr",
    symbolNative: "kr",
    minorSingle: "Aurar",
    minorPlural: "Aurar",
    ISOdigits: 0,
    decimals: 2,
    numToBasic: 100,
  },
  JEP: {
    name: "Jersey Pound",
    demonym: "Jersey",
    majorSingle: "Pound",
    majorPlural: "Pounds",
    ISOnum: null,
    symbol: "£",
    symbolNative: "£",
    minorSingle: "Penny",
    minorPlural: "Pence",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  JMD: {
    name: "Jamaican Dollar",
    demonym: "Jamaican",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 388,
    symbol: "J$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  JOD: {
    name: "Jordanian Dinar",
    demonym: "Jordanian",
    majorSingle: "Dinar",
    majorPlural: "Dinars",
    ISOnum: 400,
    symbol: "JD",
    symbolNative: "د.أ.",
    minorSingle: "Fils",
    minorPlural: "Fils",
    ISOdigits: 3,
    decimals: 3,
    numToBasic: 1000,
  },
  JPY: {
    name: "Japanese Yen",
    demonym: "Japanese",
    majorSingle: "Yen",
    majorPlural: "Yen",
    ISOnum: 392,
    symbol: "¥",
    symbolNative: "¥",
    minorSingle: "Sen",
    minorPlural: "Sen",
    ISOdigits: 0,
    decimals: 2,
    numToBasic: 100,
  },
  KES: {
    name: "Kenyan Shilling",
    demonym: "Kenyan",
    majorSingle: "Shilling",
    majorPlural: "Shillings",
    ISOnum: 404,
    symbol: "KSh",
    symbolNative: "KSh",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  KGS: {
    name: "Kyrgyzstani Som",
    demonym: "Kyrgyzstani",
    majorSingle: "Som",
    majorPlural: "Som",
    ISOnum: 417,
    symbol: "с",
    symbolNative: "с",
    minorSingle: "Tyiyn",
    minorPlural: "Tyiyn",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  KHR: {
    name: "Cambodian Riel",
    demonym: "Cambodian",
    majorSingle: "Riel",
    majorPlural: "Riels",
    ISOnum: 116,
    symbol: "៛",
    symbolNative: "៛",
    minorSingle: "Sen",
    minorPlural: "Sen",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  KID: {
    name: "Kiribati Dollar",
    demonym: "Kiribati",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: null,
    symbol: "$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  KMF: {
    name: "Comorian Franc",
    demonym: "Comorian",
    majorSingle: "Franc",
    majorPlural: "Francs",
    ISOnum: 174,
    symbol: "CF",
    symbolNative: "CF",
    minorSingle: "Centime",
    minorPlural: "Centimes",
    ISOdigits: 0,
    decimals: 2,
    numToBasic: 100,
  },
  KPW: {
    name: "North Korean Won",
    demonym: "North Korean",
    majorSingle: "Won",
    majorPlural: "Won",
    ISOnum: 408,
    symbol: "₩",
    symbolNative: "₩",
    minorSingle: "Chon",
    minorPlural: "Chon",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  KRW: {
    name: "South Korean Won",
    demonym: "South Korean",
    majorSingle: "Won",
    majorPlural: "Won",
    ISOnum: 410,
    symbol: "₩",
    symbolNative: "₩",
    minorSingle: "Jeon",
    minorPlural: "Jeon",
    ISOdigits: 0,
    decimals: 2,
    numToBasic: 100,
  },
  KWD: {
    name: "Kuwaiti Dinar",
    demonym: "Kuwaiti",
    majorSingle: "Dinar",
    majorPlural: "Dinars",
    ISOnum: 414,
    symbol: "KD",
    symbolNative: "د.ك.",
    minorSingle: "Fils",
    minorPlural: "Fils",
    ISOdigits: 3,
    decimals: 3,
    numToBasic: 1000,
  },
  KYD: {
    name: "Cayman Islands Dollar",
    demonym: "Cayman Islands",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 136,
    symbol: "CI$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  KZT: {
    name: "Kazakhstani Tenge",
    demonym: "Kazakhstani",
    majorSingle: "Tenge",
    majorPlural: "Tenge",
    ISOnum: 398,
    symbol: "₸",
    symbolNative: "₸",
    minorSingle: "Tıyn",
    minorPlural: "Tıyn",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  LAK: {
    name: "Lao Kip",
    demonym: "Lao",
    majorSingle: "Kip",
    majorPlural: "Kip",
    ISOnum: 418,
    symbol: "₭N",
    symbolNative: "₭",
    minorSingle: "Att",
    minorPlural: "Att",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  LBP: {
    name: "Lebanese Pound",
    demonym: "Lebanese",
    majorSingle: "Pound",
    majorPlural: "Pounds",
    ISOnum: 422,
    symbol: "LL.",
    symbolNative: "ل.ل.",
    minorSingle: "Qirsh",
    minorPlural: "Qirsh",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  LKR: {
    name: "Sri Lankan Rupee",
    demonym: "Sri Lankan",
    majorSingle: "Rupee",
    majorPlural: "Rupees",
    ISOnum: 144,
    symbol: "Rs.",
    symbolNative: "රු or ரூ",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  LRD: {
    name: "Liberian Dollar",
    demonym: "Liberian",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 430,
    symbol: "L$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  LSL: {
    name: "Lesotho Loti",
    demonym: "Lesotho",
    majorSingle: "Loti",
    majorPlural: "maLoti",
    ISOnum: 426,
    symbol: "L",
    symbolNative: "L",
    minorSingle: "Sente",
    minorPlural: "Lisente",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  LYD: {
    name: "Libyan Dinar",
    demonym: "Libyan",
    majorSingle: "Dinar",
    majorPlural: "Dinars",
    ISOnum: 434,
    symbol: "LD",
    symbolNative: "ل.د.",
    minorSingle: "Dirham",
    minorPlural: "Dirhams",
    ISOdigits: 3,
    decimals: 3,
    numToBasic: 1000,
  },
  MAD: {
    name: "Moroccan Dirham",
    demonym: "Moroccan",
    majorSingle: "Dirham",
    majorPlural: "Dirhams",
    ISOnum: 504,
    symbol: "DH",
    symbolNative: "د.م.",
    minorSingle: "Centime",
    minorPlural: "Centimes",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  MDL: {
    name: "Moldovan Leu",
    demonym: "Moldovan",
    majorSingle: "Leu",
    majorPlural: "Lei",
    ISOnum: 498,
    symbol: "L",
    symbolNative: "L",
    minorSingle: "Ban",
    minorPlural: "Bani",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  MGA: {
    name: "Malagasy Ariary",
    demonym: "Malagasy",
    majorSingle: "Ariary",
    majorPlural: "Ariary",
    ISOnum: 969,
    symbol: "Ar",
    symbolNative: "Ar",
    minorSingle: "Iraimbilanja",
    minorPlural: "Iraimbilanja",
    ISOdigits: 2,
    decimals: 0,
    numToBasic: 5,
  },
  MKD: {
    name: "Macedonian Denar",
    demonym: "Macedonian",
    majorSingle: "Denar",
    majorPlural: "Denars",
    ISOnum: 807,
    symbol: "den",
    symbolNative: "ден",
    minorSingle: "Deni",
    minorPlural: "Deni",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  MMK: {
    name: "Myanmar Kyat",
    demonym: "Myanmar",
    majorSingle: "Kyat",
    majorPlural: "Kyat",
    ISOnum: 104,
    symbol: "Ks",
    symbolNative: "Ks",
    minorSingle: "Pya",
    minorPlural: "Pya",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  MNT: {
    name: "Mongolian Tögrög",
    demonym: "Mongolian",
    majorSingle: "Tögrög",
    majorPlural: "Tögrög",
    ISOnum: 496,
    symbol: "₮",
    symbolNative: "₮",
    minorSingle: "möngö",
    minorPlural: "möngö",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  MOP: {
    name: "Macanese Pataca",
    demonym: "Macanese",
    majorSingle: "Pataca",
    majorPlural: "Patacas",
    ISOnum: 446,
    symbol: "MOP$",
    symbolNative: "MOP$",
    minorSingle: "Avo",
    minorPlural: "Avos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  MRU: {
    name: "Mauritanian Ouguiya",
    demonym: "Mauritanian",
    majorSingle: "Ouguiya",
    majorPlural: "Ouguiya",
    ISOnum: 929,
    symbol: "UM",
    symbolNative: "أ.م.",
    minorSingle: "Khoums",
    minorPlural: "Khoums",
    ISOdigits: 2,
    decimals: 0,
    numToBasic: 5,
  },
  MUR: {
    name: "Mauritian Rupee",
    demonym: "Mauritian",
    majorSingle: "Rupee",
    majorPlural: "Rupees",
    ISOnum: 480,
    symbol: "Rs.",
    symbolNative: "रु ",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  MVR: {
    name: "Maldivian Rufiyaa",
    demonym: "Maldivian",
    majorSingle: "Rufiyaa",
    majorPlural: "Rufiyaa",
    ISOnum: 462,
    symbol: "MRf",
    symbolNative: ".ރ",
    minorSingle: "laari",
    minorPlural: "laari",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  MWK: {
    name: "Malawian Kwacha",
    demonym: "Malawian",
    majorSingle: "Kwacha",
    majorPlural: "Kwacha",
    ISOnum: 454,
    symbol: "MK",
    symbolNative: "MK",
    minorSingle: "Tambala",
    minorPlural: "Tambala",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  MXN: {
    name: "Mexican Peso",
    demonym: "Mexican",
    majorSingle: "Peso",
    majorPlural: "Pesos",
    ISOnum: 484,
    symbol: "MX$",
    symbolNative: "$",
    minorSingle: "Centavo",
    minorPlural: "Centavos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  MYR: {
    name: "Malaysian Ringgit",
    demonym: "Malaysian",
    majorSingle: "Ringgit",
    majorPlural: "Ringgit",
    ISOnum: 458,
    symbol: "RM",
    symbolNative: "RM",
    minorSingle: "Sen",
    minorPlural: "Sen",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  MZN: {
    name: "Mozambican Metical",
    demonym: "Mozambican",
    majorSingle: "Metical",
    majorPlural: "Meticais",
    ISOnum: 943,
    symbol: "MTn",
    symbolNative: "MT",
    minorSingle: "Centavo",
    minorPlural: "Centavos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  NAD: {
    name: "Namibian Dollar",
    demonym: "Namibian",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 516,
    symbol: "N$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  NGN: {
    name: "Nigerian Naira",
    demonym: "Nigerian",
    majorSingle: "Naira",
    majorPlural: "Naira",
    ISOnum: 566,
    symbol: "₦",
    symbolNative: "₦",
    minorSingle: "Kobo",
    minorPlural: "Kobo",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  NIO: {
    name: "Nicaraguan Córdoba",
    demonym: "Nicaraguan",
    majorSingle: "Córdoba Oro",
    majorPlural: "Córdoba Oro",
    ISOnum: 558,
    symbol: "C$",
    symbolNative: "C$",
    minorSingle: "Centavo",
    minorPlural: "Centavos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  NOK: {
    name: "Norwegian Krone",
    demonym: "Norwegian",
    majorSingle: "Krone",
    majorPlural: "Kroner",
    ISOnum: 578,
    symbol: "kr",
    symbolNative: "kr",
    minorSingle: "øre",
    minorPlural: "øre",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  NPR: {
    name: "Nepalese Rupee",
    demonym: "Nepalese",
    majorSingle: "Rupee",
    majorPlural: "Rupees",
    ISOnum: 524,
    symbol: "Rs.",
    symbolNative: "रू",
    minorSingle: "Paisa",
    minorPlural: "Paise",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  NZD: {
    name: "New Zealand Dollar",
    demonym: "New Zealand",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 554,
    symbol: "NZ$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  OMR: {
    name: "Omani Rial",
    demonym: "Omani",
    majorSingle: "Rial",
    majorPlural: "Rials",
    ISOnum: 512,
    symbol: "OR",
    symbolNative: "ر.ع.",
    minorSingle: "Baisa",
    minorPlural: "Baisa",
    ISOdigits: 3,
    decimals: 3,
    numToBasic: 1000,
  },
  PAB: {
    name: "Panamanian Balboa",
    demonym: "Panamanian",
    majorSingle: "Balboa",
    majorPlural: "Balboa",
    ISOnum: 590,
    symbol: "B/.",
    symbolNative: "B/.",
    minorSingle: "Centésimo",
    minorPlural: "Centésimos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  PEN: {
    name: "Peruvian Sol",
    demonym: "Peruvian",
    majorSingle: "Sol",
    majorPlural: "Soles",
    ISOnum: 604,
    symbol: "S/.",
    symbolNative: "S/.",
    minorSingle: "Céntimo",
    minorPlural: "Céntimos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  PGK: {
    name: "Papua New Guinean Kina",
    demonym: "Papua New Guinean",
    majorSingle: "Kina",
    majorPlural: "Kina",
    ISOnum: 598,
    symbol: "K",
    symbolNative: "K",
    minorSingle: "Toea",
    minorPlural: "Toea",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  PHP: {
    name: "Philippine Peso",
    demonym: "Philippine",
    majorSingle: "Peso",
    majorPlural: "Pesos",
    ISOnum: 608,
    symbol: "₱",
    symbolNative: "₱",
    minorSingle: "Sentimo",
    minorPlural: "Sentimo",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  PKR: {
    name: "Pakistani Rupee",
    demonym: "Pakistani",
    majorSingle: "Rupee",
    majorPlural: "Rupees",
    ISOnum: 586,
    symbol: "Rs.",
    symbolNative: "Rs",
    minorSingle: "Paisa",
    minorPlural: "Paise",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  PLN: {
    name: "Polish Zloty",
    demonym: "Polish",
    majorSingle: "Zloty",
    majorPlural: "Zlotys",
    ISOnum: 985,
    symbol: "zł",
    symbolNative: "zł",
    minorSingle: "Grosz",
    minorPlural: "Groszy",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  PND: {
    name: "Pitcairn Islands Dollar",
    demonym: "Pitcairn Islands",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: null,
    symbol: "$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  PRB: {
    name: "Transnistrian Ruble",
    demonym: "Transnistrian",
    majorSingle: "Ruble",
    majorPlural: "Rubles",
    ISOnum: null,
    symbol: "р.",
    symbolNative: "р.",
    minorSingle: "Kopek",
    minorPlural: "Kopeks",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  PYG: {
    name: "Paraguayan Guaraní",
    demonym: "Paraguayan",
    majorSingle: "Guaraní",
    majorPlural: "Guaraníes",
    ISOnum: 600,
    symbol: "₲",
    symbolNative: "₲",
    minorSingle: "Centimo",
    minorPlural: "Centimos",
    ISOdigits: 0,
    decimals: 2,
    numToBasic: 100,
  },
  QAR: {
    name: "Qatari Riyal",
    demonym: "Qatari",
    majorSingle: "Riyal",
    majorPlural: "Riyals",
    ISOnum: 634,
    symbol: "QR",
    symbolNative: "ر.ق.",
    minorSingle: "Dirham",
    minorPlural: "Dirhams",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  RON: {
    name: "Romanian Leu",
    demonym: "Romanian",
    majorSingle: "Leu",
    majorPlural: "Lei",
    ISOnum: 946,
    symbol: "L",
    symbolNative: "L",
    minorSingle: "Ban",
    minorPlural: "Bani",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  RSD: {
    name: "Serbian Dinar",
    demonym: "Serbian",
    majorSingle: "Dinar",
    majorPlural: "Dinars",
    ISOnum: 941,
    symbol: "din",
    symbolNative: "дин",
    minorSingle: "Para",
    minorPlural: "Para",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  RUB: {
    name: "Russian Ruble",
    demonym: "Russian",
    majorSingle: "Ruble",
    majorPlural: "Rubles",
    ISOnum: 643,
    symbol: "₽",
    symbolNative: "₽",
    minorSingle: "Kopek",
    minorPlural: "Kopeks",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  RWF: {
    name: "Rwandan Franc",
    demonym: "Rwandan",
    majorSingle: "Franc",
    majorPlural: "Francs",
    ISOnum: 646,
    symbol: "FRw",
    symbolNative: "R₣",
    minorSingle: "Centime",
    minorPlural: "Centimes",
    ISOdigits: 0,
    decimals: 2,
    numToBasic: 100,
  },
  SAR: {
    name: "Saudi Riyal",
    demonym: "Saudi",
    majorSingle: "Riyal",
    majorPlural: "Riyals",
    ISOnum: 682,
    symbol: "SR",
    symbolNative: "ر.س.",
    minorSingle: "Halalah",
    minorPlural: "Halalahs",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  SBD: {
    name: "Solomon Islands Dollar",
    demonym: "Solomon Islands",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 90,
    symbol: "SI$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  SCR: {
    name: "Seychellois Rupee",
    demonym: "Seychellois",
    majorSingle: "Rupee",
    majorPlural: "Rupees",
    ISOnum: 690,
    symbol: "Rs.",
    symbolNative: "Rs",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  SDG: {
    name: "Sudanese Pound",
    demonym: "Sudanese",
    majorSingle: "Pound",
    majorPlural: "Pounds",
    ISOnum: 938,
    symbol: "£SD",
    symbolNative: "ج.س.",
    minorSingle: "Qirsh",
    minorPlural: "Qirsh",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  SEK: {
    name: "Swedish Krona",
    demonym: "Swedish",
    majorSingle: "Krona",
    majorPlural: "Kronor",
    ISOnum: 752,
    symbol: "kr",
    symbolNative: "kr",
    minorSingle: "Öre",
    minorPlural: "Öre",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  SGD: {
    name: "Singapore Dollar",
    demonym: "Singapore",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 702,
    symbol: "S$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  SHP: {
    name: "Saint Helena Pound",
    demonym: "Saint Helena",
    majorSingle: "Pound",
    majorPlural: "Pounds",
    ISOnum: 654,
    symbol: "£",
    symbolNative: "£",
    minorSingle: "Penny",
    minorPlural: "Pence",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  SLL: {
    name: "Sierra Leonean Leone",
    demonym: "Sierra Leonean",
    majorSingle: "Leone",
    majorPlural: "Leones",
    ISOnum: 694,
    symbol: "Le",
    symbolNative: "Le",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  SLS: {
    name: "Somaliland Shilling",
    demonym: "Somaliland",
    majorSingle: "Shilling",
    majorPlural: "Shillings",
    ISOnum: null,
    symbol: "Sl",
    symbolNative: "Sl",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  SOS: {
    name: "Somali Shilling",
    demonym: "Somali",
    majorSingle: "Shilling",
    majorPlural: "Shillings",
    ISOnum: 706,
    symbol: "Sh.So.",
    symbolNative: "Ssh",
    minorSingle: "Senti",
    minorPlural: "Senti",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  SRD: {
    name: "Surinamese Dollar",
    demonym: "Surinamese",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 968,
    symbol: "Sr$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  SSP: {
    name: "South Sudanese Pound",
    demonym: "South Sudanese",
    majorSingle: "Pound",
    majorPlural: "Pounds",
    ISOnum: 728,
    symbol: "SS£",
    symbolNative: "SS£",
    minorSingle: "Qirsh",
    minorPlural: "Qirsh",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  STN: {
    name: "Sao Tome and Príncipe Dobra",
    demonym: "Sao Tome",
    majorSingle: "Dobra",
    majorPlural: "Dobras",
    ISOnum: 930,
    symbol: "Db",
    symbolNative: "Db",
    minorSingle: "Centimo",
    minorPlural: "Centimos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  SVC: {
    name: "Salvadoran Colón",
    demonym: "Salvadoran",
    majorSingle: "Colón",
    majorPlural: "Colones",
    ISOnum: 222,
    symbol: "₡",
    symbolNative: "₡",
    minorSingle: "Centavo",
    minorPlural: "Centavos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  SYP: {
    name: "Syrian Pound",
    demonym: "Syrian",
    majorSingle: "Pound",
    majorPlural: "Pounds",
    ISOnum: 760,
    symbol: "LS",
    symbolNative: "ل.س.",
    minorSingle: "Qirsh",
    minorPlural: "Qirsh",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  SZL: {
    name: "Swazi Lilangeni",
    demonym: "Swazi",
    majorSingle: "Lilangeni",
    majorPlural: "Emalangeni",
    ISOnum: 748,
    symbol: "L",
    symbolNative: "L",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  THB: {
    name: "Thai Baht",
    demonym: "Thai",
    majorSingle: "Baht",
    majorPlural: "Baht",
    ISOnum: 764,
    symbol: "฿",
    symbolNative: "฿",
    minorSingle: "Satang",
    minorPlural: "Satang",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  TJS: {
    name: "Tajikistani Somoni",
    demonym: "Tajikistani",
    majorSingle: "Somoni",
    majorPlural: "Somoni",
    ISOnum: 972,
    symbol: "SM",
    symbolNative: "SM",
    minorSingle: "Diram",
    minorPlural: "Diram",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  TMT: {
    name: "Turkmenistan Manat",
    demonym: "Turkmenistan",
    majorSingle: "Manat",
    majorPlural: "Manat",
    ISOnum: 934,
    symbol: "m.",
    symbolNative: "T",
    minorSingle: "Tenge",
    minorPlural: "Tenge",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  TND: {
    name: "Tunisian Dinar",
    demonym: "Tunisian",
    majorSingle: "Dinar",
    majorPlural: "Dinars",
    ISOnum: 788,
    symbol: "DT",
    symbolNative: "د.ت.",
    minorSingle: "Millime",
    minorPlural: "Millime",
    ISOdigits: 3,
    decimals: 3,
    numToBasic: 1000,
  },
  TOP: {
    name: "Tongan Paʻanga",
    demonym: "Tongan",
    majorSingle: "Pa'anga",
    majorPlural: "Pa'anga",
    ISOnum: 776,
    symbol: "T$",
    symbolNative: "PT",
    minorSingle: "Seniti",
    minorPlural: "Seniti",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  TRY: {
    name: "Turkish Lira",
    demonym: "Turkish",
    majorSingle: "Lira",
    majorPlural: "Lira",
    ISOnum: 949,
    symbol: "TL",
    symbolNative: "₺",
    minorSingle: "Kuruş",
    minorPlural: "Kuruş",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  TTD: {
    name: "Trinidad and Tobago Dollar",
    demonym: "Trinidad and Tobago",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 780,
    symbol: "TT$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  TVD: {
    name: "Tuvaluan Dollar",
    demonym: "Tuvaluan",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: null,
    symbol: "$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  TWD: {
    name: "New Taiwan Dollar",
    demonym: "New Taiwan",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 901,
    symbol: "NT$",
    symbolNative: "圓",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  TZS: {
    name: "Tanzanian Shilling",
    demonym: "Tanzanian",
    majorSingle: "Shilling",
    majorPlural: "Shillings",
    ISOnum: 834,
    symbol: "TSh",
    symbolNative: "TSh",
    minorSingle: "Senti",
    minorPlural: "Senti",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  UAH: {
    name: "Ukrainian Hryvnia",
    demonym: "Ukrainian",
    majorSingle: "Hryvnia",
    majorPlural: "Hryvnias",
    ISOnum: 980,
    symbol: "₴",
    symbolNative: "грн",
    minorSingle: "Kopiyka",
    minorPlural: "kopiyky",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  UGX: {
    name: "Ugandan Shilling",
    demonym: "Ugandan",
    majorSingle: "Shilling",
    majorPlural: "Shillings",
    ISOnum: 800,
    symbol: "USh",
    symbolNative: "Sh",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 0,
    decimals: 2,
    numToBasic: 100,
  },
  USD: {
    name: "United States Dollar",
    demonym: "US",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 840,
    symbol: "$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  UYU: {
    name: "Uruguayan Peso",
    demonym: "Uruguayan",
    majorSingle: "Peso",
    majorPlural: "Pesos",
    ISOnum: 858,
    symbol: "$U",
    symbolNative: "$",
    minorSingle: "Centésimo",
    minorPlural: "Centésimos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  UZS: {
    name: "Uzbekistani Som",
    demonym: "Uzbekistani",
    majorSingle: "Som",
    majorPlural: "Som",
    ISOnum: 860,
    symbol: "сум",
    symbolNative: "сум",
    minorSingle: "Tiyin",
    minorPlural: "Tiyin",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  VED: {
    name: "Venezuelan bolívar digital",
    demonym: "Venezuelan",
    majorSingle: "Bolívar Digital",
    majorPlural: "Bolívars Digital",
    ISOnum: null,
    symbol: "Bs.",
    symbolNative: "Bs.",
    minorSingle: "Céntimo",
    minorPlural: "Céntimos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  VES: {
    name: "Venezuelan Bolívar Soberano",
    demonym: "Venezuelan",
    majorSingle: "Bolívar",
    majorPlural: "Bolívares",
    ISOnum: 928,
    symbol: "Bs.F",
    symbolNative: "Bs.F",
    minorSingle: "Centimo",
    minorPlural: "Centimos",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  VND: {
    name: "Vietnamese Dong",
    demonym: "Vietnamese",
    majorSingle: "Dong",
    majorPlural: "Dong",
    ISOnum: 704,
    symbol: "₫",
    symbolNative: "₫",
    minorSingle: "Hào",
    minorPlural: "Hào",
    ISOdigits: 0,
    decimals: 2,
    numToBasic: 10,
  },
  VUV: {
    name: "Vanuatu Vatu",
    demonym: "Vanuatu",
    majorSingle: "Vatu",
    majorPlural: "Vatu",
    ISOnum: 548,
    symbol: "VT",
    symbolNative: "VT",
    minorSingle: "",
    minorPlural: "",
    ISOdigits: 0,
    decimals: 0,
    numToBasic: null,
  },
  WST: {
    name: "Samoan Tala",
    demonym: "Samoan",
    majorSingle: "Tala",
    majorPlural: "Tala",
    ISOnum: 882,
    symbol: "T",
    symbolNative: "ST",
    minorSingle: "Sene",
    minorPlural: "Sene",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  XAF: {
    name: "Central African CFA Franc BEAC",
    demonym: "Central African CFA",
    majorSingle: "Franc",
    majorPlural: "Francs",
    ISOnum: 950,
    symbol: "Fr",
    symbolNative: "Fr.",
    minorSingle: "Centime",
    minorPlural: "Centimes",
    ISOdigits: 0,
    decimals: 2,
    numToBasic: 100,
  },
  XCD: {
    name: "East Caribbean Dollar",
    demonym: "East Caribbean",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 951,
    symbol: "$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  XOF: {
    name: "West African CFA Franc BCEAO",
    demonym: "West African CFA",
    majorSingle: "Franc",
    majorPlural: "Francs",
    ISOnum: 952,
    symbol: "₣",
    symbolNative: "₣",
    minorSingle: "Centime",
    minorPlural: "Centimes",
    ISOdigits: 0,
    decimals: 2,
    numToBasic: 100,
  },
  XPF: {
    name: "CFP Franc (Franc Pacifique)",
    demonym: "CFP",
    majorSingle: "Franc",
    majorPlural: "Francs",
    ISOnum: 953,
    symbol: "₣",
    symbolNative: "₣",
    minorSingle: "Centime",
    minorPlural: "Centimes",
    ISOdigits: 0,
    decimals: 0,
    numToBasic: 100,
  },
  YER: {
    name: "Yemeni Rial",
    demonym: "Yemeni",
    majorSingle: "Rial",
    majorPlural: "Rials",
    ISOnum: 886,
    symbol: "YR",
    symbolNative: "ر.ي.",
    minorSingle: "Fils",
    minorPlural: "Fils",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  ZAR: {
    name: "South African Rand",
    demonym: "South African",
    majorSingle: "Rand",
    majorPlural: "Rand",
    ISOnum: 710,
    symbol: "R",
    symbolNative: "R",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  ZMW: {
    name: "Zambian Kwacha",
    demonym: "Zambian",
    majorSingle: "Kwacha",
    majorPlural: "Kwacha",
    ISOnum: 967,
    symbol: "ZK",
    symbolNative: "ZK",
    minorSingle: "Ngwee",
    minorPlural: "Ngwee",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  ZWB: {
    name: "RTGS Dollar",
    demonym: "RTGS",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: null,
    symbol: "",
    symbolNative: "",
    minorSingle: "",
    minorPlural: "",
    ISOdigits: 0,
    decimals: 0,
    numToBasic: null,
  },
  ZWL: {
    name: "Zimbabwean Dollar",
    demonym: "Zimbabwean",
    majorSingle: "Dollar",
    majorPlural: "Dollars",
    ISOnum: 932,
    symbol: "Z$",
    symbolNative: "$",
    minorSingle: "Cent",
    minorPlural: "Cents",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
  Abkhazia: {
    name: "Abkhazian Apsar",
    demonym: "Abkhazian",
    majorSingle: "Apsar",
    majorPlural: "Apsark",
    ISOnum: null,
    symbol: "",
    symbolNative: "",
    minorSingle: "",
    minorPlural: "",
    ISOdigits: 0,
    decimals: 0,
    numToBasic: null,
  },
  Artsakh: {
    name: "Artsakh Dram",
    demonym: "Artsakh",
    majorSingle: "Dram",
    majorPlural: "Dram",
    ISOnum: null,
    symbol: "դր.",
    symbolNative: "դր.",
    minorSingle: "Luma",
    minorPlural: "Luma",
    ISOdigits: 2,
    decimals: 2,
    numToBasic: 100,
  },
};
