import {
  Autocomplete,
  Box,
  Button,
  Fab,
  TextField,
  Typography,
  Zoom,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Flexbetween from "components/FlexBetween";
import Navbar from "scenes/navbar";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDatas, setPage, setRef } from "state";
import {
  fieldLabels,
  formConstants,
  formulaireSasu1,
} from "constants/form.constants";
import AddressSearch from "components/AddressSearch";
import apiService from "services/apiService";
import NavigationBar from "components/NavigationBar";
import StripeProducts from "components/StripeProducts";
import { InfoOutlined } from "@mui/icons-material";
import InfoDialog from "components/InfoDialog";
import ARadioGroup from "components/ARadioGroup";
import EmptyDialog from "components/emptyDialog";

const Homepage = () => {
  const largeScreen = useMediaQuery("(min-width: 800px)");
  const dispatch = useDispatch();
  const ref = useSelector((state) => state.ref);
  const page = useSelector((state) => state.page);
  const datas = useSelector((state) => state.datas);
  const theme = useTheme();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const { data, error } = await apiService.postData("/auth/register");
        if (error) {
          console.error(error);
        }
        if (data) {
          dispatch(setRef({ ref: data.ref }));
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (!ref) {
      fetchToken();
    }
  }, [ref, dispatch]);

  const updateUserData = async (formData) => {
    try {
      const { data, error } = await apiService.postData(
        "/users/addOrUpdateUser",
        formData
      );
      if (error) {
        console.error(error);
      }

      if (data) {
        console.log("update user data successfully");
      }
    } catch (error) {
      console.error(
        "Erreur lors de la création de la session Checkout:",
        error
      );
    }
  };

  const isStepEnable = (step) => {
    if (step > 1 && !isStepEnable(step - 1)) {
      return false;
    }

    switch (step) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
        const formQuestions = formulaireSasu1[step - 1].form?.questions || [];
        return formQuestions.every((question) => {
          const value = datas[question.key];
          const condition = question.condition;
          if (condition && datas[condition.key] != condition.value) {
            return true;
          }
          return value !== undefined && value !== "";
        });
      case 10:
        return true;
      case 11:
        return (
          isStepEnable(1) &&
          isStepEnable(2) &&
          isStepEnable(3) &&
          isStepEnable(4) &&
          isStepEnable(5) &&
          isStepEnable(6) &&
          isStepEnable(7) &&
          isStepEnable(8) &&
          isStepEnable(9)
        );
      default:
        return true;
    }
  };

  const formRender = () => {
    const updateState = (key, value) => {
      const newDatas = Object.assign({}, datas);
      if (value === null || value === undefined) {
        delete newDatas[key];
      } else {
        newDatas[key] = value;
      }
      if (key === "firstTimeCreation" && value === "Oui") {
        delete newDatas["previousCreation"];
      }

      if (key === "nommerDirecteurGeneral" && value === "Non") {
        delete newDatas["identiteDirecteur"];
      }
      dispatch(setDatas({ datas: newDatas }));
      updateUserData(newDatas);
    };

    const formQuestions = formulaireSasu1[page - 1].form?.questions || [];
    const formType = formulaireSasu1[page - 1].form?.type;

    if (formType === "recapitulatif") {
      return (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "0px",
          }}
        >
          {formulaireSasu1.map((section, index) => {
            if (["recapitulatif", "stripe"].includes(section.form.type)) {
              return null;
            }
            return (
              <Box sx={{ mb: "30px", width: "100%" }}>
                <Typography key={section.label} variant="h2">
                  {section.label}
                </Typography>
                {section.form?.questions?.map((question) => {
                  const value = datas[question.key];
                  if (!value) {
                    return null;
                  }
                  return (
                    <Flexbetween
                      sx={{
                        gap: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (index !== -1) {
                          dispatch(setPage({ page: index + 1 }));
                        }
                      }} // Naviguer vers la page correspondante
                    >
                      <Typography>{fieldLabels[question.key]}:</Typography>
                      <Typography variant="h6">{value}</Typography>
                    </Flexbetween>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      );
    } else if (formType === "stripe") {
      return <StripeProducts />;
    }

    return formQuestions.map((question) => {
      if (question.condition) {
        if (datas?.[question.condition.key] != question.condition.value) {
          return null;
        }
      }
      if (question.type === "textfield") {
        return (
          <TextField
            value={datas?.[question.key]}
            onChange={(e) => updateState(question.key, e.target.value)}
            fullWidth
            label={fieldLabels[question.key]}
            name={question.key}
          />
        );
      } else if (question.type === "numberfield") {
        return (
          <TextField
            type="number"
            value={datas?.[question.key]}
            onChange={(e) => updateState(question.key, e.target.value)}
            fullWidth
            label={fieldLabels[question.key]}
            name={question.key}
          />
        );
      } else if (question.type === "radio") {
        return (
          <Box>
            {question.displayLabel && (
              <Typography>{fieldLabels[question.key]}</Typography>
            )}
            <ARadioGroup
              name={question.key}
              choices={question.options}
              value={datas?.[question.key]}
              onChange={(v) => updateState(question.key, v)}
            />
          </Box>
        );
      } else if (question.type === "activityList") {
        return (
          <Autocomplete
            disablePortal
            id={question.key}
            options={formConstants.ACTIVITY_LIST}
            value={datas?.[question.key]}
            fullWidth
            onChange={(event, value) => updateState(question.key, value)}
            renderInput={(params) => (
              <TextField {...params} label={fieldLabels[question.key]} />
            )}
            ListboxProps={{
              style: {
                maxHeight: "150px",
              },
            }}
          />
        );
      } else if (question.type === "address") {
        return (
          <AddressSearch
            defaultValue={datas?.[question.key]}
            label={fieldLabels[question.key]}
            onSelectionChange={(address) =>
              updateState(question.key, address.properties.label)
            }
          />
        );
      } else if (question.type === "calendar") {
        return (
          <TextField
            type="date"
            value={datas?.[question.key]}
            onChange={(e) => updateState(question.key, e.target.value)}
            fullWidth
            label={fieldLabels[question.key]}
            name={question.key}
          />
        );
      }
    });
  };

  const formulaireRender = () => {
    return (
      <Fragment>
        <Flexbetween
          sx={{
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
          }}
        >
          <Typography
            variant={largeScreen ? "h3" : "h4"}
            sx={{ fontWeight: 600 }}
          >
            {formulaireSasu1[page - 1].form?.title}
          </Typography>
          {page < 10 && (
            <Typography variant="h5">
              Ces informations nous permettront de vous assister au cours de
              votre processus de création, et seront necessaires pour constituer
              votre dossier.
            </Typography>
          )}
        </Flexbetween>
        {formRender()}
        <Flexbetween gap={2}>
          {page > 1 && (
            <Button
              onClick={() => {
                if (page > 1) {
                  dispatch(setPage({ page: page - 1 }));
                }
              }}
              variant="contained"
            >
              retour
            </Button>
          )}
          {page < 10 && (
            <Button
              sx={{ pl: "24px", pr: "24px" }}
              onClick={() => {
                if (page < 10) {
                  dispatch(setPage({ page: page + 1 }));
                }
              }}
              disabled={!isStepEnable(page)}
              variant="contained"
              endIcon={<ArrowRightAltIcon />}
            >
              Continuer
            </Button>
          )}
        </Flexbetween>
        <InfoDialog
          title="Information sur vos données"
          message="Vos données sont protegées par la loi : LKmedia, éditeur du site Alcée
          avocats, collecte des données personelles à l'aide du present
          questionnaire afin d'assurer les services proposés sur le site. Vous
          disposez d'un droit d'accès, de rectification, de suppression et
          d'opposition au traitement des données à caractère personel vous
          concernant. Vous pouvez exercer ces droits à l'adresse email suivante:
          privacy@alcéeavocats.fr. Pour plus dinformations, veuillez consulter
          notre charte de respect de la vie privée"
        >
          <Flexbetween gap="10px">
            <InfoOutlined />
            Information sur vos données
          </Flexbetween>
        </InfoDialog>
      </Fragment>
    );
  };

  const videoCard = () => {
    if (largeScreen) {
      return (
        <Box
          sx={{
            borderRadius: "16px",
            aspectRatio: "0.8",
            height: "80%",
            maxHeight: "400px",
            overflow: "hidden",
            position: "sticky",
            top: "220px",
          }}
        >
          <video
            style={{ objectFit: "cover", objectPosition: "center" }}
            width="100%"
            height="100%"
            controls
            src={
              "https://www.alceeavocats.com/wp-content/uploads/2023/05/Alcee-Header.mp4"
            }
          ></video>
        </Box>
      );
    } else {
      return (
        <Box sx={{ position: "fixed", bottom: 10, right: 5, opacity: 0.6 }}>
          <EmptyDialog
            content={
              <Box
                sx={{
                  borderRadius: "16px",
                  aspectRatio: "0.8",
                  height: "80%",
                  maxHeight: "400px",
                  overflow: "hidden",
                }}
              >
                <video
                  style={{ objectFit: "cover", objectPosition: "center" }}
                  width="100%"
                  height="100%"
                  controls
                  src={
                    "https://www.alceeavocats.com/wp-content/uploads/2023/05/Alcee-Header.mp4"
                  }
                ></video>
              </Box>
            }
          >
            <Zoom
              key="inherit"
              in={true}
              timeout={100}
              style={{
                transitionDelay: "100ms",
              }}
              unmountOnExit
            >
              <Fab
                sx={{
                  color: "common.white",
                  bgcolor: theme.palette.primary.main,
                  "&:hover": {
                    bgcolor: theme.palette.primary.dark,
                  },
                }}
                aria-label="Expand"
                color="inherit"
              >
                Video
              </Fab>
            </Zoom>
          </EmptyDialog>
        </Box>
      );
    }
  };

  return (
    <Box sx={{ height: "100%", overflowY: "scroll" }}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "#FFF",
          zIndex: 999,
        }}
      >
        <Navbar />
        <NavigationBar isStepEnable={(index) => isStepEnable(index)} />
      </Box>
      <Flexbetween
        sx={{
          justifyContent: "center",
          gap: "4rem",
          alignItems: "flex-start",
          minHeight: "calc(100% - 120px)",
        }}
      >
        {videoCard()}
        <Box
          sx={{
            width: largeScreen ? (page === 11 ? "50%" : "40%") : null,
            padding: "20px",
            mt: "50px",
          }}
        >
          <Flexbetween
            sx={{ flexDirection: "column", alignItems: "flex-start", gap: 4 }}
          >
            {formulaireRender()}
          </Flexbetween>
        </Box>
      </Flexbetween>
    </Box>
  );
};

export default Homepage;
